import React from 'react'

import ArticleHeader from '../elements/articleHeader'
import { PLAYER_BENEFITS_DATA } from '../content/playerBenefits'
import { getRandomColor } from '../helpers/colors'
import Animation from '../elements/animation'

const PlayerBenefits = () => (
  <div
    id="features"
    className="
      flex
      flex-col
      flex-wrap
      font-sans
      items-center
      justify-center
      px-4
      md:px-12
      py-20
      relative
      w-screen
      space-y-8
      text-blue-800
      text-center
      tracking-wide
    "
  >
    <Animation numberOfElements={12} />
    <ArticleHeader
      subtitle="Give your audience a first class listening experience on the web by providing access all of your metadata like chapters, transcripts and much more"
      title="So many features to support the openess of podcasting"
    />
    <div
      className="
        flex
        flex-wrap
        px-12
        max-w-6xl
      "
    >
      { PLAYER_BENEFITS_DATA.benefits.map(item =>
        <div
          key={JSON.stringify(item)}
          className="
            text-left
            px-4
            py-12
            w-full
            sm:w-1/2
            lg:w-1/3
          "
        >
          <div
            className={`
              bg-${getRandomColor()}
              h-10
              items-center
              justify-center
              flex
              my-4
              rounded-md
              text-white
              w-10
            `}
          >
            <img src={item.icon} />
          </div>
          <h3
            className="
              font-bold
              pb-4
              text-xl
            "
          >{item.title}</h3>
          <p>{item.copy}</p>
        </div>
      )}
    </div>
  </div>
)

export default PlayerBenefits