export const PLAYER_BENEFITS_DATA = {
  benefits: [
    {
      copy: 'Every podcast is unique. This should also be reflected in the listening experience of your audience. Customise the player according to your wishes so that it is optimally tailored to your target group.',
      icon: '../images/icons/podcast--white.png',
      title: 'Made for Podcasters',
    },
    {
      copy: 'The Web Player supports a variety of audio formats in all major browsers as well as dedicated download functionality for other media formats related to your podcast.',
      icon: '../images/icons/audio-file--white.png',
      title: 'Audio file formats and downloads',
    },
    {
      copy: 'The Web Player uses the Podlove Simple Chapters format that allows your listeners to easily navigate within your podcast.',
      icon: '../images/icons/chapter-marks--white.png',
      title: 'Podcast Chapters',
    },
    {
      copy: 'Sharing and embedding does not have to be tied to a platform. The Web Player allows content to be shared on any website via a simple embed code.',
      icon: '../images/icons/share--white.png',
      title: 'Sharing & Embedding',
    },
    {
      copy: 'Transcripts make your podcast searchable and allows your listeners to explore your audio content in a whole new way. The web player offers a unique visualisation.',
      icon: '../images/icons/transcript--white.png',
      title: 'Transcripts',
    },
    {
      copy: 'Live audio content has special requirements when it comes to playback. The web player supports HLS live streams and offers tailored audio controls for an optimal user experience.',
      icon: '../images/icons/headset--white.png',
      title: 'Live Streaming',
    },
  ]
}