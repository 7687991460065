export const PLAYER_INSTALLATION_DATA_STANDALONE = {
  data: [
    {
      copy: 'The easiest way to integrate the player is to simply integrate this script in your page. For https context:',
      code: '<script src="https://cdn.podlove.org/web-player/embed.js"></script>',
      title: 'Add the script to your page',
    },
    {
      copy: 'To initialise the player, use the following code:',
      code: '<script>podlovePlayer("#example", "/path/to/episode/definition/or/object", "/path/to/configuration/or/object");</script>',
      title: 'Initialize the player',
    }
  ]
}

export const PLAYER_INSTALLATION_DATA_WORDPRESS = {
  data: [
    {
      copy: 'Download the Podlove Web Player Plugin to your desktop. If downloaded as a zip archive, extract the Plugin folder to your desktop.',
      title: 'Download the plugin',
    },
    {
      copy: 'With your FTP program, upload the Plugin folder to the wp-content/plugins folder in your WordPress directory online.',
      title: 'Upload the plugin',
    },
    {
      copy: 'Go to Plugins screen and find the newly uploaded Plugin in the list. Click Activate Plugin to activate it.',
      title: 'Activate the plugin',
    },
    {
      copy: 'Use a simple shortcode in your posts and pages, and the Podlove Web Player will appear, playing any media file you want to assign. Basic usage:',
      code: '[podlove-web-player\ntheme="default"\nconfig="default"\ntitle="My episode title"\nsubtitle="Episode Subtitle"\nposter="/files/path/to/poster.png"\nchapters="/files/path/to/chapters.json"\ntranscripts="/files/path/to/transcripts.json"\nplaylist="/files/path/to/playlist.json"\nsrc="http://mysite.com/mymedia.mp3"\nsize="1337"\nshow="My show title"\nduration="03:33"]',
      title: 'Add the WordPress Shortcode',
    }
  ]
}