import React from 'react'

import Layout from '../components/layout'
import Seo from '../components/seo'
import Hero from '../components/hero'
import PlayerBenefits from '../components/playerBenefits'
import PlayerInstallation from '../components/playerInstallation'
import PlayerMoreInformation from '../components/playerMoreInformation'
import PlayerPreview from '../components/playerPreview'

const NAV_ITEMS = [
  {
    name: 'Preview',
    path: '#preview',
  },
  {
    name: 'Features',
    path: '#features',
  },
  {
    name: 'Installation',
    path: '#installation',
  },
]

const IndexPage = () => (
  <Layout
    subHeaderNavItems={NAV_ITEMS}
    subHeaderNavTitle={'Podlove Web Player'}
    subHeaderColor={'podlove-orange-500'}
  >
    <Seo title="Home" />
    <Hero
      copy="Podlove Web Player enables you to deliver the best possible listening experience to your audience with excellent user experience and lots of additional features like chapter marks and transcription."
      cta="Install Web Player"
      ctaColor={'podlove-orange-500'}
      headline="The fast, flexible and responsive podcast player"
    />
    <PlayerPreview />
    <PlayerBenefits />
    <PlayerInstallation />
    <PlayerMoreInformation />
  </Layout>
)

export default IndexPage
