import React from 'react'
import { PodloveWebPlayer } from '@podlove/player-react'
import ArticleHeader from '../elements/articleHeader'

const PlayerPreview = () => (
  <div
    className="
      bg-blue-800
      flex
      flex-col
      flex-wrap
      font-sans
      items-center
      justify-center
      px-12
      py-20
      w-screen
      space-y-8
      text-white
      text-center
      tracking-wide
    "
    id="preview" 
  >
    <ArticleHeader
      subtitle="Podlove Web Player reacts to its environment and is fully responsive. Every feature is easy to access and use. See for yourself and play around with it:"
      title="So flexible and easy to use. Give it a try!"
    />

    <div className="md:w-1/2">
      <PodloveWebPlayer
        episode="https://backend.podlovers.org/wp-json/podlove-web-player/shortcode/publisher/60"
        config="https://backend.podlovers.org/wp-json/podlove-web-player/shortcode/config/podlovers/theme/default"
      />
    </div>
  </div>
)

export default PlayerPreview
