import React, { useState } from 'react'
import { PLAYER_INSTALLATION_DATA_STANDALONE, PLAYER_INSTALLATION_DATA_WORDPRESS } from '../content/playerInstallation'
import ArticleHeader from '../elements/articleHeader'
import DocsList from '../elements/docsList'
import TabsContainer from '../elements/tabsContainer'

const PlayerInstallation = () => {
  const [firstTabActive, setFirstTabActive] = useState(true)

  return (
    <div
      id="installation"
      className="
        bg-gray-200
        md:flex
        md:flex-col
        md:flex-wrap
        font-sans
        items-center
        justify-center
        px-12
        py-20
        w-screen
        space-y-8
        text-blue-800
        text-center
        tracking-wide
        z-10
      "
    >
      <ArticleHeader
        title="Easy to install on every plattform you use"
        subtitle="Integrate the player into almost any page or use the WordPress plugin for an easy installation on your blog."
      />
      <div
        className="
          md:flex
          md:flex-wrap
          px-12
          max-w-6xl
        "
      ></div>
      <TabsContainer
        firstTabActive={firstTabActive}
        onPressLeft={() => setFirstTabActive(true)}
        onPressRight={() => setFirstTabActive(false)}
        titleLeft="Standalone"
        titleRight="WordPress"
      >
        { firstTabActive && PLAYER_INSTALLATION_DATA_STANDALONE.data.map((item, index) =>
          <DocsList
            code={item.code}
            copy={item.copy}
            key={index}
            number={index + 1}
            title={item.title}
          />
        )}
        { !firstTabActive && PLAYER_INSTALLATION_DATA_WORDPRESS.data.map((item, index) =>
          <DocsList
            code={item.code}
            copy={item.copy}
            key={index}
            number={index + 1}
            title={item.title}
          />
        )}
      </TabsContainer>
    </div>
  )
}

export default PlayerInstallation